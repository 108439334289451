import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RichText from "../components/RichText/RichText"
import Search from "../components/Search/Search"
import { Disqus } from "gatsby-plugin-disqus"

const SectionTemplate = ({ data, location, pageContext }) => {
  const page = data.contentfulSection
  const homePage = data.contentfulPage
  const config = data.site.siteMetadata

  let disqusConfig = {
    url: `${config.siteUrl + location.pathname}`,
    identifier: page.id,
    title: page.title,
  }

  return (
    <Layout location={location}>
      <Seo title={page.title} description="" url={location.pathname} />

      <div className="grid">
        <div className="grid__sidebar">
          <h2 style={{ marginTop: "1.1em" }}>Search</h2>
          <Search />
        </div>
        <div className="grid__main">
          <h1>{page.title}</h1>
          <p>Last updated: {page.updatedAt}</p>

          {page.slug === "/" ? (
            // home page
            <RichText paragraph={homePage.mainContent} />
          ) : (
            // index page
            <>
              <hr />
              <h2>In this section:</h2>
              <nav>
                <ul>
                  {data.allContentfulPage.edges.length
                    ? data.allContentfulPage.edges.map(page => (
                        <li key={page.node.slug}>
                          <Link
                            to={`/${page.node.section.slug}/${page.node.slug}/`}
                          >
                            {page.node.title}
                          </Link>
                        </li>
                      ))
                    : "No pages in this section yet"}
                </ul>
              </nav>
            </>
          )}

          <hr style={{ marginBottom: "2em" }} />
          <Disqus config={disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}

export default SectionTemplate

export const pageQuery = graphql`
  query SectionPages($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulSection(slug: { eq: $slug }) {
      title
      slug
      updatedAt(formatString: "D MMMM, YYYY")
    }
    allContentfulPage(
      filter: { section: { slug: { eq: $slug } } }
      sort: { fields: menuOrder }
    ) {
      edges {
        node {
          title
          slug
          section {
            slug
          }
        }
      }
    }
    allContentfulSection(sort: { fields: menuOrder }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    contentfulPage(slug: { eq: "/" }) {
      mainContent {
        raw
        references {
          ... on ContentfulPage {
            __typename
            contentful_id
            slug
            title
            section {
              slug
            }
          }
          ... on ContentfulSection {
            __typename
            contentful_id
            slug
            title
          }
        }
      }
    }
  }
`
